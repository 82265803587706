import * as React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull, ImageCenter } from '../../components/imageelements'
import { SynopsisScene, SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import AudioSet from '../../components/audioset'
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'

const characters = [
  {name: 'Princess Turandot', voice: 'Soprano', desc: 'A fierce man-hating spinster who aims to kill anyone wanting to marry her, in the belief that she is avenging a mistreated ancestor.'},
  {name: 'The Emperor', voice: 'Tenor', desc: 'Father of Turandot. His name is Altoum in the libretto, but no one speaks during the opera.'},
  {name: 'Timur', voice: 'Bass', desc: 'Formerly king of Tartary. Currently old and blind.'},
  {name: 'Calaf', voice: 'Tenor', desc: 'A prince, anonymous to everyone in the opera. Supposedly a great guy but does little to justify this reputation.'},
  {name: 'Liu', voice: 'Soprano', desc: 'A slave girl. Loves Calaf beyond all measure -- the feeling’s not mutual.'},
  {name: 'Ping', voice: 'Baritone', desc: 'The first of three commedia dell’arte characters, all of whom, despite great music, are potentially offensive, 19th-century stereotypes. The Lord Chancellor.'},
  {name: 'Pang', voice: 'Tenor', desc: 'The second Commedia character. The Majordomo.'},
  {name: 'Pong', voice: 'Tenor', desc: 'The third Commedia character. The head chef of the palace.'},
];

const inbrief = {name: 'Turandot', composer: "Giacomo Puccini", librettist: "Giuseppe Adami and Renato Simoni", language: "Italian", date: 'April 25th, 1926', acts: "Three", musiclength: "Two hours, five minutes"};

const bibliography = [
  {name: 'Puccini Without Excuses: A Refreshing Reassessment of the World\'s Most Popular Composer', writer: 'William Berger', url: 'https://amzn.to/2gt5h3j'},
  {name: 'Puccini\'s Turandot : The End of the Great Tradition', writer: 'William Ashbrook & Harold Powers', url: 'https://amzn.to/2fzxLnL'},
  {name: 'The Birth of an Opera: Fifteen Masterpieces from Poppea to Wozzeck', writer: 'Michael Rose', url: 'https://amzn.to/2fzqQLn'},
  {name: 'Turandot', writer: 'Scores at the International Music Score Library Project', url: 'https://imslp.org/wiki/Turandot_(Puccini,_Giacomo)'},
  {name: 'Turandot\'s Homecoming: Seeking the Authentic Princess of China in a New Contest of Riddles', writer: 'Master of Music thesis by Ying-Wei Tiffany Sung, Graduate College of Bowling Green State University', url: 'https://etd.ohiolink.edu/apexprod/rws_etd/send_file/send?accession=bgsu1273466517&disposition=inline'},
  {name: 'Turandot: The Chinese Sphinx', writer: 'Friedrich von Schiller', url: 'https://amzn.to/2gsKKbu'},
]

// markup
const TurandotPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Turandot | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/turandot" />
          <meta name="description" content="A guide to Giacomo Puccini's mammoth, Ancient China set Turandot. Including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>
        <Hero title="Turandot" subtitle="An Opera by Giacomo Puccini"/>
        <MainBodySection>
          <p><em>Turandot</em> is Puccini’s final opera (unfinished at his death) and perhaps as a result, it is by far and away his most musically adventurous. As in <em><Link to="/operas/butterfly">Madama Butterfly</Link></em>, the score is filled with Asian touches, the percussion section in particular packed with gongs and various tuned instruments (xylophones, glockenspiels and the like). It is still, however, an Italian opera with the outrageously titled Ping, Pang and Pong a spin on classic Commedia dell’arte characters.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/turandot/ROHTurandot.jpg" alt="ROH Turandot Banner" title="The Royal Opera's Turandot"/>
              <figcaption>Turandot at the Royal Opera House</figcaption>
            </ImageFull>

            <div id="ezoic-pub-ad-placeholder-102"> </div>

            <p>It’s an opera that blends epic chorus passages with some brilliantly intimate moments. Indeed the chorus plays a much more significant role than in Puccini’s other operas, acting as an onstage witness to well over half of the action. The opening is a powerful series of five chords, said musically to depict an executioner’s axe falling, and that hair-raising effect is spectacularly well maintained throughout.</p>
            
            <AudioSet audiosrc="/audio/turandot/OpeningChords.mp3" desc="The Opening of Turandot"/>

            <p><em>Turandot</em> also contains arguably the most famous tenor aria in all of opera, "Nessun Dorma". An instantly recognizable piece of music which you'll come to learn has lost its dark meaning by being repeatedly considered out of context.</p>

            <p>The conclusion stretches incredulity even by operatic standards, but as Puccini didn’t write it, he can’t be blamed!</p>

            <Header title="In Brief"/>

            <InBrief content={inbrief}/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-103"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Act I - Running Time: 35 mins</SynopsisAct>

            <p>The curtain rises on a surging crowd. A mandarin announces that anyone wishing to marry Princess Turandot must answer three riddles. Failure means death. The Prince of Persia has just failed and will be executed when the moon rises.</p>

            <p>The crowd's bloodlust is dealt with brutally by the palace guards, and Timur, a blind, older man, is knocked to the ground. Liu, his slave, cries out for help, and Calaf comes to their aid. As luck would have it, Calaf is Timur’s son! Timur is the deposed king of Tartary, making Calaf a prince. Only Liu has remained faithful to him. Calaf warns them not to mention his name as he fears being discovered by the Emperor.</p>

            <VideoPlayer src="https://www.youtube.com/embed/NXDfLVy1gRg?start=255&autoplay=1" id="NXDfLVy1gRg" title="Turandot at the Forbidden City"/>

            <ImageRight>
              <StaticImage src="../../images/operas/turandot/Turandot_RoyalOpera_CalafSpike.jpg" alt="Calaf is surrounded by blades in the Royal Opera production"/>
              <figcaption>Calaf (Jose Cura) &copy; Johan Persson/Royal Opera</figcaption>
            </ImageRight>

            <p>As the moon rises, the Prince of Persia is led to his death. The crowd appeals in his favour, but Turandot appears and signals for the execution to continue. Calaf sees Turandot and falls in love. The Prince of Persia is executed.</p>

            <p>Blinded by Turandot’s beauty, Calaf is about to rush forward and bang the gong, signalling that he wishes to take the riddles when Ping, Pang and Pong appear. The jaunty threesome tells him not to risk it, as do Timur and Liu, the latter being not very secretly in love with Calaf. Calaf is touched but never the less runs forward, shouting Turandot’s name and bangs the gong three times. Turandot accepts his challenge as the curtain falls on Act I.</p>

            <SynopsisAct>Act II - Running Time: 50 mins</SynopsisAct>

            <SynopsisScene>Scene 1</SynopsisScene>

            <p>Ping, Pang and Pong discuss their place in society, interspersing humour - do we prepare for a wedding or a funeral - and nostalgia - we are living in an era of endless death. A trumpet sounds announcing the entrance of the Emperor.</p>

            <SynopsisScene>Scene 2</SynopsisScene>

            <p>The Emperor asks Calaf to withdraw his challenge. Turandot emerges and begins to describe why no man may possess her. (This is the first music she sings in the opera.) Her ancestor Princess Lo-u-Ling ruled until she was raped and murdered by a foreign prince, and Turandot believes Lo-u-Ling lives in her. Out of revenge, no man will ever have her. She advises Calaf to withdraw, but he is having none of it.</p>

            <p>She gives the three riddles...</p>

            <h4>What is born each night and dies each dawn?</h4>
            <h4>What flickers red and warm like a flame, but is not fire?</h4>
            <h4>What is like ice, but burns like fire?</h4>

            <p>...each of which he correctly answers. Turandot is distraught at being forced to marry and pleads with her father, but he insists that she go through with it. Calaf, believing love will win out, gives her one possible escape: he is a prince, and if she can learn his name before sunrise, then he will die at dawn. Turandot accepts, and the curtain falls - the Emperor hoping the Prince will be his son.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act III - Running Time: 40 mins</SynopsisAct>

            <SynopsisScene>Scene 1</SynopsisScene>

            <p>Turandot has commanded that no one sleep until the Prince’s name is discovered. Everyone will be killed if they don’t learn the name and she has to marry. Calaf awaits the dawn, and while he’s at it sings a ditty called "Nessun Dorma" (you’ve probably heard it before).</p>

            <VideoPlayer src="https://www.youtube.com/embed/2RdJmqLrsbo?autoplay=1" id="2RdJmqLrsbo" title="Placido Domingo sings Nessun Dorma"/>

            <p>Ping, Pang and Pong turn up and try to buy off Calaf with women and riches. He is not interested. They then drag in Timur and Liu but Calaf pretends he knows nothing about them. They get roughed up a bit, and suddenly Liu, to protect Timur, claims that she alone knows the Prince’s name but will not give it up. She is tortured but still refuses. Turandot asks why she would suffer such pain, and she answers <strong>love</strong>. Turandot doesn’t think much of this answer, so she has Liu tortured further. Liu tells Turandot that she will soon learn love before promptly taking a dagger off a soldier and stabbing herself.</p>

            <p>Liu falls dead! Timur must be informed since he is blind, and he cries out in sadness. The crowd and he leave with the body of Liu. Calaf chastises Turandot for effectively slaughtering the heart and soul of the opera and then pulls her in and kisses her. (At this point, Puccini finishes, and Alfano takes over).</p>

            <p>After the kiss Turandot is horrified, but gradually she softens. She reveals that she has always (since Act I at least) both hated and loved the Prince. She tells him to leave, but he bravely reveals his name: “I am Calaf, son of Timur.” His life sits in her hands...</p>

            <SynopsisScene>Scene 2</SynopsisScene>

            <p>Predictably but rather unbelievably - not to mention mawkishly - the couple approach the Emperor, and Turandot announces that she knows the Prince’s name and it is love. Everyone is happy, and the opera ends to the tune of "Nessun Dorma".</p>

            <ImageFull>
              <StaticImage src="../../images/operas/turandot/Turandot-Met_Full.jpg" alt="Turandot at the Met"/>
              <figcaption>Turandot finale in MET's Zeffirelli production &copy; Marty Sohl/Met Opera</figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Where in the World"/>

            <p><i>Turandot</i> takes place in fictional ancient China. Peking is specified (marked in Red), which is the old name for Beijing, but the opera might set it in Shanghai for all the geographical details.</p>

            <Header title="The Origins of the Story"/>

            <ImageRight maxwidth="250px">
              <StaticImage src="../../images/operas/turandot/turandot_nizami.jpg" alt="Nizami Ganjavi"/>
              <figcaption>Nizami Ganjavi</figcaption>
            </ImageRight>

            <p>The story of Turandot originates in a French collection of fairy tales by François Pétis de la Croix “Les mille et un jours” translated into English as “The thousand and one days”. You might know “One thousand and one nights” or the “Arabian Nights” from which we acquired Aladdin, Sinbad, Ali Baba and a host of other famous tales. These collections shouldn’t be confused however, “Nights” is made up of genuine Middle Eastern stories compiled in Arabic over centuries. “Days” has a more dubious provenance. In many cases, these stories only exist in de la Croix’s rendering leading some to believe he might have conjured them up himself. If Turandot has a precursor, it is a 12th Century work by the Azerbaijani poet and philosopher Nizami Ganjavi “Seven Beauties”.</p>

            <p>In any case, Puccini arrived at the text through a Schiller play that was, in turn, a German version of an Italian commedia dell'arte by Carlo Gozzi, who took the story from the de la Croix. Puccini was first introduced to the text in an Italian translation of the Schiller by Andrea Maffei who is perhaps most famous for writing the libretto to Verdi’s <i>I masnadieri</i>. Puccini wasn’t the first composer to get to it either. Antonio Bazzini wrote <i>Turanda</i> in 1867, Ferruccio Busoni <i>Turandot</i> in 1917 only a few years before Puccini began his. Puccini had not heard Busoni’s version but Bazzini had been a teacher of Puccini’s in Milan.</p>

            <Header title="History"/>

            <ImageRight maxwidth="190px">
              <StaticImage src="../../images/operas/turandot/TurPremiere.jpg" alt="Premiere Program from La Scala"/>
              <figcaption>Premiere Program from La Scala</figcaption>
            </ImageRight>

            <p>Puccini was always a fastidious and deliberate composer, but the writing of <em>Turandot</em> was an especially drawn-out process. In the four years before his death, Puccini vacillated over the number of acts, whether or not he should turn his attention to something less serious, and fixated over the final love duet that he believed was the essential, earth-shattering culmination of the entire evening. Despite these circumstances, he poured himself into the work, writing to a friend in March 1924, “I have placed, in this opera, all my soul.”</p>

            <p><em>Turandot</em> premiered at La Scala in Milan on 25 April 1926, almost a year and a half after Puccini’s death. Puccini’s friend Arturo Toscanini, who had worked on the score with the composer during the last months of Puccini’s life, conducted the premiere. As is widely recorded, when the opera reached the last note written by Puccini, Toscanini ended the performance. What he said at the time has been variously reported, from the poetic “Here death triumphed over art” to the poignant “For me, the work ends here.” An eyewitness quoted in a recent biography puts it somewhere between the two: “Here ends the opera, because at this point the maestro was dead.” The Alfano version was presented the following night.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Popular?</FunFactsHeader>

            <ImageRight  maxwidth="90px">
              <StaticImage src="../../images/operas/turandot/BirgitNilsson.png" alt="Birgit Nilsson as Turandot"/>
              <figcaption>Birgit Nilsson as Turandot</figcaption>
            </ImageRight>

            <p>The 16th most performed opera in the world from 2007-2012, according to Operabase. This frequency was not always the case. Beginning in 1930, <em>Turandot</em> was not performed for more than three decades at the Met, after Maria Jeritza, who premiered the role there, sang her last of 23 Turandots. It did not return until 1961 when Birgit Nilsson took on the princess, a role she owned in New York until 1970, singing it 52 times. The long break was due at least partly to a dearth of sopranos capable of singing the difficult title role, a criterion which some opera lovers of a certain age feel should be applied today to any role ever sung by Maria Callas. </p>

            <FunFactsHeader>In China</FunFactsHeader>

            <p>Until the end of the 20th century, <em>Turandot</em> was banned in the People’s Republic of China because the government felt the opera portrayed China unfavourably. The ban ended in style, with an epic performance in 1998 given in the Forbidden City, the home of actual Chinese emperors, until they stopped having them in 1912. The production was originally staged and conducted in Florence, Italy, by Zubin Mehta and Chinese film director Zhang Yimou (who later directed the opening and closing ceremonies of the Beijing Olympics). <a href="http://amzn.to/2fxwvBx">A documentary called “The Turandot Project”</a> tells how the production eventually came to China and how it was adapted. There is also a video of the actual concert (you can see a clip in the synopsis), with the snappy title <a href="http://amzn.to/2gvvwUn">“Turandot at the Forbidden City of Beijing.”</a></p>

            <FunFactsHeader>Vincero</FunFactsHeader>

            <p>The second syllable of <em>vincerò</em>, “I will win,” is a high B, near the top of most tenors’ ranges and a nice note for them to show off. As the Ricordi vocal score shows, that B is just a sixteenth note, a grace note. The final syllable, on A, is supposed to be held, although again not nearly as long as it generally is by the large-lunged tenors of today. While Lucciano Pavarotti is credited with popularizing this basking in high-B land, the trend goes back much further. One of the few (if not the only) tenors to end the aria the way Puccini wrote it is Francesco Merli, heard here from the first complete recording of <em>Turandot</em>, done in 1938. Compare with Pavarotti, live from San Francisco in 1977.</p>

            <ImageCenter>
              <StaticImage src="../../images/operas/turandot/NBmusic.png" alt="Nessun Dorma Sheet Music"/>
            </ImageCenter>

            <AudioSet audiosrc="/audio/turandot/Merli_ND.mp3" desc="Francesco Merli sings Nessun Dorma, 1938"/>

            <AudioSet audiosrc="/audio/turandot/Pav_ND.mp3" desc="Pavarotti sings Nessun Dorma, 1977"/>

            <div id="ezoic-pub-ad-placeholder-107"> </div>

            <FunFactsHeader>The Ending</FunFactsHeader>

            <p>How to end <em>Turandot</em> remains an ongoing problem. After Puccini’s death, his friend Arturo Toscanini, who would conduct the premiere, suggested the task of finishing the opera be given to the young composer Franco Alfano. Alfano had piles of Puccini’s sketches from which to work. Still, much of it was difficult to decipher, including the intriguing note regarding the love duet “Then Tristan...” referring to Wagner’s opera Tristan und Isolde, which ends breathtakingly as the soprano transfigures in a love-death to be with her true love who has already died.</p>

            <p>An edited version of Alfano’s ending, which no one finds satisfactory, pretty much rules opera houses today, although in 2001 the Italian composer Luciano Berio, produced a version that many critics feel should supplant the Alfano version. Among other attempts were Janet Maguire’s rather less successful go in the 1970s and Chinese composer Hao Weiya (see the Mo Li Hua fun fact).</p>

            <FunFactsHeader>Mo Li Hua</FunFactsHeader>

            <p>The Jasmine Flower song, or Mo Li Hua, was one of several traditional Chinese tunes that Puccini incorporated into the score of <em>Turandot</em>. He likely heard the piece on a music box belonging to his friend Baron Alberto Fassini. It is still sufficiently popular that it is a track on Disney's Baby Einstein: World Music album. It was also the tune used by Chinese composer Hao Weiya to end the opera in his 2008 attempt at (another) new ending. That latest attempt was, perhaps not surprisingly, adored by the Chinese press but probably will not supplant the Alfano ending any time soon.</p>

            <AudioSet audiosrc="/audio/turandot/Jasmine_Flower.mp3" desc="Mo Li Hua from Baby Einstein: World Music"/>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default TurandotPage
